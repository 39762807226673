import React from 'react'
import styled, { css } from 'styled-components'

const Flex = styled.div`
    position: relative;
    display: flex;
    color: ${props => props.color};
    padding: ${props => props.padding};
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};
    white-space: pre-wrap;
    ${props => (props.column || props.row)
        ? css`
            height: 100%;
            width: 100%;
            flex-direction: ${props.column ? 'column' : 'row'};
        `
        : css`
            flex: ${props => props.n || 1};
        `}
`

export default Flex