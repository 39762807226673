export default {
    dark: {
        backgroundColor: 'black',
        textColor: 'white',
        linkColor: '#00FFFF',
        header: {
            activeColor: 'rgba(0,0,0,0.025)'
        },
        projects: {
          imageFilter: 'invert(1.0) grayscale(1) contrast(10)'
        },
        blocks: {
          color: 'inherit'
        },
        services: {
            default: 'gray',
            github: '#FFFFFF',
            thingiverse: '#FF0000',
            goodreads: '#0000FF',
            flickr: '#FFFF00',
            reddit: '#00FFFF',
            spotify: '#FF00FF',
            foursquare: '#FF0000',
            soundcloud: '#00FFFF',
            dribbble: '#00FF00',
            twitter: '#FFFF00'
        }
    },
    light: {
        backgroundColor: 'white',
        textColor: 'black',
        linkColor: '#0000FF',
        header: {
            activeColor: 'rgba(0,0,0,0.025)'
        },
        projects: {
          imageFilter: 'grayscale(1) contrast(10)'
        },
        blocks: {
          color: 'white'
        },
        services: {
            default: 'white',
            github: '#000000',
            thingiverse: '#00FFFF',
            goodreads: '#FFFF00',
            flickr: '#0000FF',
            reddit: '#FF0000',
            spotify: '#00FF00',
            foursquare: '#00FFFF',
            soundcloud: '#FF0000',
            dribbble: '#FF00FF',
            twitter: '#0000FF'
        }
    }
}