import React, { useEffect, useState } from 'react';
import './hacks'
import './App.css';
import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group'

import { logError, logAction } from './analytics'

import config from './config.json'
import themes from './theme.js'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

import * as Blocks from './components/Blocks'
import { Fill } from './components/BlendModes'
import Flex from './components/Flex'

import { capitalizeFirstLetter, shuffleArray } from './utils'
import TextLogo from './components/TextLogo';

const FA_LINKEDIN = 61580

const GlobalCSS = createGlobalStyle`
  body {
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.textColor};
  }
`

const AppWrapper = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  ${Fill} {
    opacity: ${props => props.colorized && 0};
  }
  
  a:visited { color: #FF00FF; }
`

const ProjectLink = styled.a`
  /* color: pink; */
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    opacity: 0.75;
  }
`

const ProjectLogo = styled.img`
  height: 1rem;
  width: 1rem;
  filter: ${props => !props.neutral && props.theme.projects.imageFilter};
`

const Grid = styled.div`
  display: inline-grid;
  width: 100%;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  & a { 
    color: inherit;
    text-decoration: inherit; 
    &:visited { color: inherit; }
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1920px) {
    grid-template-columns: repeat(6, 1fr);
  }
`

const BlockWrapper = styled.div`
  overflow: hidden;
  @media (max-width: 480px) {
    height: 90vw;
  }
  @media (min-width: 480px) {
    height: 45vw;
  }
  @media (min-width: 640px) {
    height: 32vw;
  }
  @media (min-width: 1024px) {
    height: 25vw;
  }
  @media (min-width: 1440px) {
    height: 20vw;
  }
  @media (min-width: 1920px) {
    height: 15vw;
  }
`

const HeaderText = styled.h1`
  font-weight: normal;
  font-size: medium;
  margin: 0;
  padding: 0;
`

const PointerEvent = styled.div`
  padding: 0.5rem 1rem;
  transition: background-color 0.5s ease-out, border 0.5s ease-out;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  user-select: none;
  &:hover {
    border-color: ${props => props.theme.header.activeColor};
  }
  &:active {
    background-color: ${props => props.theme.header.activeColor};
  }
`

const getBlock = (block) => {
  const { service } = block;
  const componentName = capitalizeFirstLetter(service)
  return Blocks[componentName] || Blocks.Default
}

const renderBlock = (block, i) => {
  const { Component } = getBlock(block)
  const handleClick = () => logAction(block.link, 'blockClick')
  return !block.disabled && (
    <a href={block.link} onMouseDown={handleClick} key={block.link} target="_blank">
      <BlockWrapper>
        <Component {...block} />
      </BlockWrapper>
    </a>
  )
}

const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

function App() {
  const [endpoints, setEndpoints] = useState([])
  const [blocks, setBlocks] = useState([])
  const [randomized, setRandomized] = useState(false)
  const [colorized, setColorized] = useState(false)
  const [themeId, setThemeId] = useState(isDarkMode ? 'dark' : 'light')
  const sortedBlocks = !randomized 
    ? blocks.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : blocks
  useEffect(() => {
    axios.get(`${config.endpoint}?endpoints`)
      .then((res) => setEndpoints(res.data))
      .catch(logError('endpoints'))
  },[])
  useEffect(() => {
    for (const endpoint of endpoints) {
      axios.get(`${config.endpoint}?s=${endpoint}`)
        .then((res) => {
          // format and filter values
          const { data } = res
          if (data.map) {
            const formatted = data
              .filter((block) => getBlock(block).filter(block))
              .map((block) => getBlock(block).format(block))
            setBlocks((b) => [...b, ...formatted])
          } else {
            logError(endpoint)({ message: 'Probably throttled. No data to map.' })
          }
        })
        .catch(logError(endpoint))
    }
  }, [endpoints])

  const shuffleBlocks = () => {
    logAction('shuffle', 'fun')
    setRandomized(true)
    setBlocks((b) => shuffleArray(b))
  }

  const colorizeBlocks = () => {
    logAction('colorize', 'fun')
    setColorized(!colorized)
  }

  const prankedAlert = (service) => () => {
    logAction(service, 'social')
    window.alert('haha pranked, why dont you mail me\n victor @ ingman.me')
  }

  const handleThemeToggle = () => {
    const newTheme = themeId === 'light'
      ? 'dark'
      : 'light'
    setThemeId(newTheme)
  }

  const styleNavItem = { padding: '0 1rem' }
  return (
    <ThemeProvider theme={themes[themeId]}>
      <GlobalCSS/>
    <AppWrapper colorized={colorized}>
      <Flex justifyContent="center" padding="2.5rem 2.5rem 1rem 2.5rem">
        <PointerEvent onClick={handleThemeToggle}>
        <HeaderText>Victor Ingman</HeaderText>
        </PointerEvent>
      </Flex>
      <Flex justifyContent="center" padding="0 1rem 2rem 1rem">
        <ProjectLink href="http://gpukollen.ingman.me" target='_blank' rel='noopener noreferrer' style={styleNavItem}>
          <ProjectLogo src='/assets/images/gpukollen-logo.png' alt='GPUKollen' title='GPUKollen' />
        </ProjectLink>
        <ProjectLink href="https://takt.ingman.me" target='_blank' rel='noopener noreferrer' style={styleNavItem}>
          <ProjectLogo src='https://takt.ingman.me/favicon.ico' alt='TAKT' title='TAKT' />
        </ProjectLink>
        <ProjectLink href="https://ascout.ingman.me" target='_blank' rel='noopener noreferrer' style={styleNavItem}>
          <ProjectLogo src='https://ascout.ingman.me/favicon.png' alt='ASCOUT' title='ASCOUT' />
        </ProjectLink>
        <ProjectLink href="https://chrome.google.com/webstore/detail/modest-guitar-columns-for/kbdobpkcobapldadlpcjbjijmjnjfddk" target='_blank' rel='noopener noreferrer' style={styleNavItem}>
          <ProjectLogo src='/assets/images/mg-logo.png' alt='Modest Guitar' title='Modest Guitar' />
        </ProjectLink>
      </Flex>
      <Flex justifyContent="center" padding="0 0 0.5rem 0">
        <a href="#shuffle" style={styleNavItem} onClick={shuffleBlocks}>shuffle</a>
        <a href="#color" style={styleNavItem} onClick={colorizeBlocks}>{!colorized ? 'colorize' : 'fuck'}</a>
      </Flex>
        <CSSTransitionGroup
          component={Grid}
          transitionName='block'
          transitionEnterTimeout={1000}>
          {sortedBlocks.map(renderBlock)}
        </CSSTransitionGroup>
      </AppWrapper>
      </ThemeProvider>
  );
}

export default App;
