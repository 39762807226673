export const logAction = (id, category='click') => {
    const { gtag } = window
    gtag && gtag('event', id, {
      'event_category': category,
    })  
}

export const logError = (id, fatal = false) => (err) => {
  const { gtag } = window
  gtag && gtag('event', 'exception', {
    description: `[${id}] ${err.message}`,
    fatal
  })
  console.group(id)
  console.log(err)
  console.groupEnd()
}