import React from 'react'
import styled from 'styled-components'
import { parse, fromUnixTime, formatDistanceToNow }  from 'date-fns'
import { Overlay, Color, Group, Hue } from './BlendModes'
import Flex from './Flex'
import TextLogo from './TextLogo'

const _BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-size: ${props => props.contain ? 'contain' : 'cover'};
    background-position: center center;
`

const validImageSrc = (imageSrc) => imageSrc != null && 
  (
    imageSrc.toLowerCase?.().match(/^(http|https)/) != null
  )

const CenterFlex = (props) => <Flex justifyContent="center" alignItems="center" >{ props.children }</Flex>

const BackgroundImage = (props) => {
    const { alt, ...rest } = props
    return <_BackgroundImage {...rest}>
        { alt && <span role="img" aria-label={alt} />}
    </_BackgroundImage>
}

const Description = styled.div`
    height: 15%;
    width: 100%;
    background-color: ${props => props.theme.backgroundColor};
    border-top-width: 1px;
    border-top-style: solid;
`

const BlockWrapper = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.color || 'black'};
    box-sizing: border-box;
    & > ${Description} {
        border-color: ${props => props.color || 'black'};
        color: ${props => props.color || props.theme.blocks.color};
    }
`

const Content = styled.div`
    flex: 1;
    display: flex;
    overflow: hidden;
    position: relative;
`

const ContentPadding = styled.div`
    display: flex;
    padding: 1rem;
    flex: 1;
`



const DefaultBlock = ({ content, description, color, children }) => (
    <BlockWrapper color={color}>
        { children
            ? children
            : <>
                <Content>
                    <ContentPadding>
                        {content}
                    </ContentPadding>
                </Content>
                <Description>{description}</Description>
            </>}
    </BlockWrapper>
)

const DescriptionBlock = ({ date, icon, color, service }) => {
    return <Flex alignItems="center" row>
        <Flex color={color} padding="1rem" n="4">{formatDistanceToNow(new Date(date))} ago</Flex>
        <Flex color={color} padding="1rem" justifyContent="flex-end">
            {typeof(icon) === "object"
                ? icon
                : <TextLogo title={service && `This is from ${service}`}>{ icon }</TextLogo>}
        </Flex>
    </Flex>
}

export const Default = {
    format: (block) => {
        const { createdAt } = block
        let isoString
        let disabled = false
        try {
            isoString = new Date(createdAt).toISOString()
        } catch(e) {
            disabled = true
            console.log(`[${block.service}] Broken parse of createdAt`, block)
        }
        return {
            ...block,
            disabled,
            createdAt: isoString
        }
    },
    filter: function keepAllByDefault () {
      return true
    },
    Component: (props) => {
        const { text, image, createdAt } = props
        const firstLetter = props.service[0].toUpperCase()
        return <DefaultBlock
        color='gray'
        content={<>
            <Hue color='white' />
            <BackgroundImage src={image} alt={props.text} contain/>
        </>}
        description={<DescriptionBlock date={createdAt} icon={firstLetter} service={props.service} />} />
    }
}

export const Thingiverse = {
    ...Default,
    icon: 'T',
    color: '#00FFFF',
    Component: (props) => (
        <DefaultBlock
                color={Thingiverse.color}
                content={
                    <>
                    <Color color={Thingiverse.color} />
                    <BackgroundImage src={props.image} alt={props.text} /> 
                    </>
                }
                description={
                    <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Thingiverse.icon} 
                    service={props.service} />
                } />
    )
}

export const Goodreads = {
    ...Default,
    color: "#FFFF00",
    icon: 'G',
    filter: (block) => {
      return block.createdAt != null && block.createdAt !== ''
    },
    format: (block) => {
      // weird format to parse, example: "Mon Jan 30 02:43:39 -0800 2023"
      const date = parse(block.createdAt, 'EEE MMM dd HH:mm:ss xx yyyy', new Date())
      const createdAt = date.toISOString()
      const scaledImage = block.image.replace('SX98', 'SX512')
      return {
        ...block,
        createdAt,
        image: scaledImage
      }
    },
    Component: (props) => (
        <DefaultBlock
                color={Goodreads.color}
                content={
                  <>
                    <Color color='#FF0' />
                    <BackgroundImage src={props.image} alt={props.text} /> 
                  </>
                }
                description={
                    <DescriptionBlock
                        color='black'
                        date={props.createdAt} 
                        icon={Goodreads.icon}
                        service={props.service} />
                } />
    )
}

export const Github = {
    ...Default,
    color: "#000000",
    icon: String.fromCharCode(61715),
    Component: (props) => (
        <DefaultBlock
                color={Github.color}
                content={
                    <Flex column overlay>
                        <Flex justifyContent="center" alignItems="flex-end" padding="1rem">Created</Flex>
                        <Flex justifyContent="center" alignItems="flex-start" padding="1rem">
                            <span style={{color: 'red', textDecoration: 'underline'}}>{ props.text }</span>
                        </Flex>
                    </Flex>
                }
                description={
                    <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Github.icon} 
                    service={props.service} />
                } />
    )
}

export const Flickr = {
    ...Default,
    color: '#0000FF',
    icon: String.fromCharCode(61806),
    format: (block) => {
        return {
            ...block,
            createdAt: fromUnixTime(block.createdAt).toISOString()
        }
    },
    Component: (props) => {
        return (
            <DefaultBlock
                color={Flickr.color}
                content={
                    <>
                    <Group>
                        <Color color='#FF00FF' />
                        <Overlay color={Flickr.color} />
                    </Group>
                    <BackgroundImage src={props.image} alt={props.text} /> 
                    </>
                }
                description={
                    <DescriptionBlock 
                        color='#FF00FF'
                        date={props.createdAt} 
                        icon={Flickr.icon} 
                        service={props.service} />
                } />
        )
    }
}

export const Reddit = {
    ...Default,
    color: '#FF0000',
    icon: String.fromCharCode(61857),
    format: (block) => {
        return {
            ...block,
            createdAt: fromUnixTime(block.createdAt).toISOString()
        }
    },
    Component: (props) => (
        <DefaultBlock
                color={Reddit.color}
                content={
                    validImageSrc(props.image)
                    ? (
                      <>
                        <Color color={Reddit.color} />
                        <BackgroundImage src={props.image} alt={props.text} /> 
                      </>
                    )
                    : <CenterFlex>{props.text}</CenterFlex>
                }
                description={
                    <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Reddit.icon} 
                    service={props.service} />
                } />
    )
}
export const Spotify = {
    ...Default,
    color: '#00FF00',
    icon: String.fromCharCode(61884),
    format: (block) => {
        const { createdAt } = block
        const date = parse(createdAt, 'yyMMdd', new Date())
        const isoString = date.toISOString()
        return {
            ...block,
            createdAt: isoString
        }
    },
    Component: (props) => (
        <DefaultBlock
                color={Spotify.color}
                content={
                    <>
                    <Color color={Spotify.color} />
                    <BackgroundImage src={props.image} alt={props.text} /> 
                    </>
                }
                description={
                    <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Spotify.icon} 
                    service={props.service} />
                } />
    )
}

export const Foursquare = {
    ...Default,
    color: '#00FFFF',
    icon: String.fromCharCode(61824),
    format: (block) => {
        return {
            ...block,
            ...Default.format(block),
            createdAt: fromUnixTime(block.createdAt).toISOString()
        }
    },
    Component: (props) => (
        <DefaultBlock
                color={Foursquare.color}
                content={
                    <>
                    <Color color={Foursquare.color} />
                    <BackgroundImage src={props.image} alt={props.text} /> 
                    </>
                }
                description={
                    <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Foursquare.icon} 
                    service={props.service} />
                } />
    )
}
export const Soundcloud = {
    ...Default,
    color: '#FF0000',
    icon: String.fromCharCode(61886),
    Component: (props) => (
        <DefaultBlock
                color={Soundcloud.color}
                content={
                    <>
                    <Color color={Soundcloud.color} />
                    <BackgroundImage src={props.image} alt={props.text} /> 
                    </>
                }
                description={
                    <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Soundcloud.icon} 
                    service={props.service} />
                } />
    )
}

export const Dribbble = {
    ...Default,
    color: '#FF00FF',
    icon: String.fromCharCode(61821),
    Component: (props) => (
        <DefaultBlock
                color={Dribbble.color}
                content={
                    <>
                    <Color color={Dribbble.color} />
                    <BackgroundImage alt={props.text} src={props.image} /> 
                    </>
                }
                description={
                    <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Dribbble.icon} 
                    service={props.service} />
                } />
    )
}

export const Twitter = {
    ...Default,
    color: '#0000FF',
    icon: String.fromCharCode(61593),
    Component: (props) => {
        const { text, image, data } = props
        const { quotedImage, quotedText, isQuoteStatus, isDeleted } = data
        const TwitterImage = (props) => <>
            <Color color={Twitter.color} />
            <BackgroundImage {...props} alt={text} contain/>
        </>
        return <DefaultBlock 
            color={Twitter.color} 
            content={isQuoteStatus
                ? <Flex column>
                    <Flex justifyContent="center" alignItems="center">
                        { quotedImage
                            ? <TwitterImage src={quotedImage} />
                            : !isDeleted
                                ? <i style={{ color: Twitter.color }}>"{ quotedText }"</i>
                                : <i>deleted</i>
                        }
                    </Flex>
                    <Flex justifyContent="center" alignItems="center">
                        &gt; { text }
                    </Flex>
                </Flex>
                : <Flex column>
                    { text && <CenterFlex>{text}</CenterFlex> }
                    { image && <CenterFlex><TwitterImage src={image} /></CenterFlex> }
                </Flex>
            }
            description={
                <DescriptionBlock 
                    date={props.createdAt} 
                    icon={Twitter.icon}
                    service={props.service} />
            }
            />
    }
}