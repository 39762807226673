/**
 * Credits: https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
 * Date: 16 April 2020
 * @ Steve Harrison
 * @param {*} string 
 */
export function capitalizeFirstLetter(string) {
    const firstChar = string.charAt(0)
    return firstChar.toUpperCase() + string.slice(1);
}

/**
 * Credits: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 * Date: 17 April 2020
 * @ Laurens Holst
 * @param {*} string 
 */
export function shuffleArray(src) {
    let array = Array.from(src)
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}